import React, { useState, useEffect } from 'react';
import { getFirestore } from 'firebase/firestore';



export default function BookOrderDashboard({ id, client, transfer, payment, fullbookdate, day, item, month, subtotal, paid, service, status, time, total, year, cancel, blockTimes, transferTrue, address}) {

  // const date = bookdate.day + "/" + bookdate.month + "/" + bookdate.year

  const [switchApprove, setSwitchApprove] = useState(true)
  const [switchText, setSwitchText] = useState(false)
  const [cancelling, setCancelling] = useState(false)

  const newdate = new Date();


  const cancellingUpdate = () => {
    setCancelling(true)
    setTimeout(() => {
      setCancelling(false)
    }, 2000);
  }




  const onSwitch = () => {
    if (switchApprove === false) {
      setSwitchApprove(true)
    } if (switchApprove === true) {
      setSwitchApprove(false)
    }
  }

  const hoverSwitchText = () => {
    if (switchText === false) {
      setSwitchText(true)
    } if (switchText === true) {
      setSwitchText(false)
    }
  }


  const mensajeDom = `hola ${client.name}! Soy Valu de la Coque. el día ${day}/${month} a las ${time} tenemos turno en tu espacio.\n\n ✂ Me repetirías tu dirección así me queda por este medio? \n ✂ por favor, esperame con el pelo limpio o sin productos de finalizar (cremas o geles)\n ✂ siempre trato de ser puntual, pero por cuestiones de traslado entre casa y casa, el margen de demora puede ser de hasta 10 minutos  \n ✂ en caso de que necesites cancelar, avisando con más de 24 horas se reprograma o devuelve la seña. con menos tiempo, se pierde la seña y la posibilidad de programar nuevamente el turno.\n\n Muchas gracias y te veo en tu espacio :)`;
  const baseUrl = "https://api.whatsapp.com/send/?phone=";
  const phoneNumber =  client !== null ? client.phone : null; 
  const encodedMessageDom = encodeURIComponent(mensajeDom);
  const fullUrlDom = `${baseUrl}${phoneNumber}&text=${encodedMessageDom}`;
  
  const mensajeLocal=  client !== null ? `hola ${client.name}! soy valu de la coque. el día ${day}/${month} a las ${time} tenemos turno en el COQUEstudio:) 
\n\n 📍 La dirección es 59 #993 casi esquina 15 (espacio de OtraCasaclub). 
  \n 〰️ la coqueteria esta en el piso de arriba. si tenes dificultades para subir escaleras ¡avisanos! que nos adaptamos a tu situación 
  \n 👉🏻 venir con pelo limpio o sin productos de finalizar (cremas o geles) 
  \n ⏰ xfi tratar de ser puntual asi no hacemos esperar a los siguientes clientes. 
  \n 〰️ si venis en auto, considera el tiempo de estacionamiento! es una zona con poca disponibilidad 
  \n 👉🏻 en caso de no poder asistir, avisando con mas de 24 hs se reprograma o devuelve la seña. con menos de ese tiempo, se pierde la seña y la posibilidad de programar de nuevo el turno. ➖no avisando o cancelandouna hora antes, se deberá abonar el valor completo del turno. 
  \n  🤩 en el espacio de @otracasa_ funcionan algunos talleres y emprendimientos. también podes venir a merendar o tomar matecitos que la casa esta abierta al publico de 16 a 20hs . pasate cuando quieras a compartir el rato! 🥰
  \n\n muchas gracias, te espero!
  \n valu de @lacoque.teria 🙋🏼‍♀️`: null
  
  const encodedMessageLocal = encodeURIComponent(mensajeLocal);
  const fullUrlLocal = `${baseUrl}${phoneNumber}&text=${encodedMessageLocal}`;
  
  
  

  return (


    <div className='dashboardBookOrder'>
      <div className={status !== false ? 'bookDataTop' : 'bookDataTop cancelled'}>
        <div>
          <p className='clientText'>
            {client.name.toUpperCase()}
          </p>
        </div>

        <div className='dateTextCont'>
          <p className='dateText '>
            DÍA: <span className='pink'>{day}/{month}/{year}</span>
          </p>

          <p className='dateText '>
            HORA: <span className='pink'> {time}</span>
          </p>

          <p className='dateText'>
            MODALIDAD: <span className='pink'>{service === "local" || service === "color" ? "COQUEstudio" : "Domicilio"}</span>
          </p>

          <p className='dateText'>
            SERVICIO: <span className='pink ellipsis'>{item}</span>
          </p>


        </div>

      </div>


      <div className='dateData'>

      <p className='modalText modalMini'> DNI {client.dni}</p>
      
      </div>

        {address && (
          <p className='modalText modalMini'>Dirección: {address}</p>
        ) }


    
      <div className='dateData'>

        <div className='minitext'>
          {status !== null ?

            <>
              <p>{status === false ? "CANCELADA" : "CONFIRMADA"}</p>
              <button className={status === false ? 'switch off' : 'switch on'} onClick={onSwitch} onMouseEnter={hoverSwitchText} onMouseLeave={hoverSwitchText} > <span className={status === false ? 'switchBallOff' : cancelling === true ? "switchBallCancelling" : 'switchBallOn'}>⬤</span> </button>

              <button className={switchApprove === false && status === true ? 'cancelBookButton' : 'displayNone'} onClick={() => {
                cancel(blockTimes, service, month, year, day, id); cancellingUpdate()
              }}> CANCELAR </button>
            </>

            :

            <>
              <div className='column'>
                <p className='pending bookConditionText minitext'>Estado: PENDIENTE</p>

                <div className='row'>
                  <button onClick={null} className='on bookConditionText minitext cursorPointer'>CONFIRMAR</button>
                  <button onClick={null} className='off bookConditionText minitext cursorPointer'>CANCELAR</button>
                </div>
              </div>


            </>
          }


        </div>

        <button className='actionButton' onClick={() => window.location = `mailto: ${client.email}`}>
          <img className='actionImg' src="https://img.icons8.com/ios-filled/100/000000/new-post.png" />
        </button>



        <button className='actionButton' onClick={() => window.location = service === "local" || service === "color" ? `${fullUrlLocal}`:  `${fullUrlDom}` }>
          <img className='actionImg' src="https://img.icons8.com/ios-glyphs/240/000000/whatsapp.png" />
        </button>

        <button className='actionButton' onClick={() => window.location = `tel:${Number(client.phone)}`}>
          <img className='actionImg' src="https://img.icons8.com/ios-glyphs/90/000000/phone-disconnected.png" />
        </button>

      </div>


      <div className='dateData'>
        <img className='actionImg' src="https://img.icons8.com/windows/96/null/banknotes.png" />
        <p className='modalText modalMini'>{payment.toUpperCase()}</p>
      </div>
      <div className='dateData'>


        <p className=' button3 '> TOTAL: ${total}</p>
        {paid === true || transfer === true ? <p className='button2'>SEÑA: $7000</p> : <></>}
        {paid === false && transfer === false ? <p className='cancelButton44'> SIN SEÑA</p> : <></> }
     
      </div>
      {transfer === false ? <button onClick={() => {
        transferTrue(id)
      }} className='button3 button2minitext'> MARCAR SEÑA PAGADA </button> : <></> }


      <p className={switchText === false ? "switchText displayNone" : "switchText"}>
        ¡CUIDADO! Esta acción es irreversible. Cambiar el estado de confirmación restablecerá los días que estaban reservados. No te olvides de avisarle al cliente sobre la cancelación.
        {/*                 
                 BE CAREFUL! This action cannot be undone. Switching the confirmation status will re-enable the spots that were reserved. Don't forget to notify the customer about the cancellation. */}
      </p>
    </div>
  )
}


